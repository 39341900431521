.link-editor {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    max-width: 400px;
    width: 100%;
    opacity: 0;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: opacity 0.5s;
    will-change: transform;
    padding: 9px;
    align-items: center;
  }
  .link-view {
    flex-grow: 1;
  }
  .link-editor .button {
    width: 20px;
    height: 20px;
    display: inline-block;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 2px;
  }
  
  .link-editor .button.hovered {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: #eee;
  }
  
  .link-editor .button i,
  .actions i {
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: -0.25em;
  }
  .link-edit, .link-trash {
    padding: 3px 10px 2px;
    background-color: #4a4ae1;
    border-radius: 3px;
    color: #fff;
  }
  .link-trash {
    background-color: #da4e2d;
    margin-left: 10px;
  }