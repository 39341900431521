.image-thumbnail input {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  opacity: 0;
  z-index: 2;
}
.image-thumbnail {
  position: relative;
  height: 220px;
  width: 220px;
  background-color: var(--soft-theme-color);
  border: 1px solid var(--input-border);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.image-thumbnail img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
}
.sidebar-post-container {
  height: calc(100vh - 100px);
  width: 320px;
  position: sticky;
  top: 0;
  overflow: hidden;
  padding: 0;
}
.sidebar-post-body {
  padding: 10px;
}
.sidebar-post p {
  font-size: 14px;
}

.sidebar-post .title {
  font-size: 20px;
  font-weight: 800;
}
.sidebar-post img {
  width: 100%;
  display: block;
  margin-bottom: 20px;
}
@media (max-width: 600px) {
  .image-thumbnail {
    height: 150px;
    width: 150px;
  }
  .sidebar-post-container {
    display: none;
  }
}
