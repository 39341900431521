@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --input-border: #fff;
  --background-color: #161718;
  --text-color: #fff;
  --theme-color: #101012;
  --soft-theme-color: #1f1f23;
  --mid-theme-color: #19191c;
  --card-border: #0000001a;
  --box-shadow: 0px 0px 3px 0px #00000014;
  --font-nunito: "Montserrat", sans-serif
}


.light {
  --input-border: #ccd8ff;
  --input-background: #f8faff;
  --background-color: #e1e3e8;
  --text-color: #232125;
  --theme-color: #e5e6ea;
  --soft-theme-color: #fcfcff;
  --mid-theme-color: #eaeaf0;
  --divider-border: #e7e9f0;
}

.card-block-header {
  border-bottom: 1px solid var(--divider-border);
}

.card-block-header {}

body {
  --bs-body-bg: transparent;
  --bs-body-color: initial;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color) !important;
  font-size: 15px;
}
a {
  text-decoration: unset;
  color: inherit;
}
.modal-content {
  background-color: var(--soft-theme-color);
}

.form-group {
  padding: 12px 0 0;
}

.form-control {
  border: unset
}

.form-group label {
  margin-bottom: 6px;
  font-weight: 500;
}

.form-control:focus {
  /* color: initial; */
}

.form-control {
  border: 1px solid var(--input-border);
  border-radius: 4px;
  background-color: var(--input-background);
}

.card-block {
  background: var(--soft-theme-color);
  border-radius: 6px;
  /* padding: 20px; */
  /* margin-bottom: 20px; */
  border: unset;
  box-shadow: var(--box-shadow);
}

.card-header {
  background-color: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.edit-button {
  height: 30px;
  width: 30px;
  padding: 0;

}

.edit-button svg {
  height: 20px;
  width: 20px;
}

.image-resizer {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
}

.image-resizer.image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

.image-resizer.image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

.image-resizer.image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

.image-resizer.image-resizer-se {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

.image-resizer.image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

.image-resizer.image-resizer-sw {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

.image-resizer.image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

.image-resizer.image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

.image-blog-container {
  cursor: default;
  /* display: inline-block; */
  position: relative;
  user-select: none;
  /* display: block; */
}

.image-blog-container img {
  display: block;
}

.editor {
  height: 100%;
  overflow: auto;

}

.toolbar {
  display: flex;
  padding: 13px;
  justify-content: center;
  /* border-bottom: 1px solid #e1e1e1; */
  /* background: #ffffff; */
  position: sticky;
  top: 0;
  background: #eeeeef;
  z-index: 1;
  border-bottom: 1px solid #8383832e;
}

.toolbar button {
  margin-right: 3px;
  margin-left: 3px;
  font-size: 15px;
  background: transparent;
  border: 1PX SOLID #313131;
  width: 40PX;
  height: 40px;
  border-radius: 3px;
}

.toolbar button i {
  /* color: inherit; */
  /* display: block; */
}

.toolbar button.active {
  border: 1px solid #bcc7f5;
}

.toolbar hr {
  margin-left: 10px;
  margin-right: 10px;
}

.editor-inner {
  /* height: calc(100vh - 120px); */
  overflow: auto;
  position: relative;

}

.editor-container {
  background: var(--soft-theme-color);
  box-shadow: var(--box-shadow);
  /* margin-top: 18px; */
  /* margin-bottom: 15px; */
  border-radius: 6px;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: calc(100vh - 100px);
  /* display: flex; */
  /* flex-direction: column; */
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #d8d8e5;
  position: relative;
}

.editor-content-editable {
  outline: unset;
  /* border: 1px solid #bbb; */
  /* max-width: 700px; */
  margin: auto;
  padding: 10px;
  border-top: unset;
  /* height: 100%; */
  overflow: auto;
  position: relative;
  min-height: calc(100vh - 175px);
}
.editor-content-editable pre span {
  display: block;
}
.editor-content-editable code span{
  display: unset;
}
.editor-placeholder {
  position: absolute;
  z-index: 0;
  left: 10px;
  top: 10px;
}
.modal-editor-file-background {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 0;
  top: 0;
  left: 0;
  background-color: #0000001a;
}
.modal-editor-file {
  position: fixed;
    background-color: #fff;
    padding: 12px;
    width: 290px;
    box-shadow: 0px 0px 4px 0 #00000042;
    margin-top: 18%;
    border-radius: 8px;
}
.modal-editor-file input{
  margin-top: 10px;
  width: 100%;
}
.ImageNode__contentEditable {
  min-height: 20px;
  color: grey;
  font-size: 14px;
  outline: unset;
  /* background-color: #00000014; */
}
.ImageNode__contentEditable p{
  margin: 0;
  text-align: center;
  cursor: auto;
}
.image-caption-container {
  position: relative;
  background-color: #00000014;
  padding: 5px;
}

.placeholder-caption {
  position: absolute;
    top: 3px;
    color: #8f8787;
}
.dropdown-menu {
  background-color: #fff;
  max-height: 174px;
  overflow-y: auto;
}
@media (max-width: 600px) {
  .toolbar {
    flex-wrap: wrap;
    justify-content: unset;
    padding-bottom: 0px;
  }
  .toolbar button{
    margin-bottom: 10px;
    
  }
}